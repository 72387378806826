.Community-Engagement-Page {
  margin: 0px 0px 50px;
}

.Community-Engagement-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.06em;
  color: #000000;
  padding: 10px 75px 0px 75px;
  background: linear-gradient(rgb(159, 191, 89), rgb(244, 249, 224));
  margin: 0px;
}

.Community-Sections-Header {
  /*Hero */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
  padding: 0px 50px;
}

.Community-Sections-Div {
  position: relative;
  background: rgba(201, 226, 101, 0.2);
  margin: 100px 0px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-margin-top: 50px;
}

.Volunteer-Accordian-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  margin: 0px;

  color: #000000;
}

.Volunteer-Accordian-Font-Text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  /* or 100% */
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;

  color: #000000;
}

.Linus-Law-Quote {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #008037;
  text-align: center;
}

.F2PM-Details-Description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #000000;
}

.Food-Sovereignty-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.04em;
  width: 100%;

  color: #000000;
}

.Food-Sovereignty-Definition {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #000000;
}

.Community-Tab-Panel {
  /* background: #F4F9E0; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
}
