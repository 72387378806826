.Section-Line-Divider {
  border-top: 1px solid #0000006e;
}

.Red-Text {
  color: #ed462f;
}

.Bold-Text {
  font-weight: 900;
  color: Black;
}

.Banner-Child-Container {
  border: solid 1px black;
  border-radius: 4px;
  width: 75%;
  margin: 5px 0px 5px 0px;
}

.Banner-Parent-Container {
  background: #f5a623;
  display: flex;
  justify-content: center;
}

.Title-Page-Text {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.Title-Dom-Text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 0.06em;
  color: #ffffff;
  margin: 0px;
}

.Title-Sub-Text {
  /* Header 2 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.06em;
  color: #ffffff;
  margin: 0px;
  background: #3b8138;
  padding: 0px 10px 2px;
  border-radius: 15px;
}

.Title-Buttons {
  display: flex;
  gap: 25px;
  margin-right: 100px;
  margin-top: 30px;
}

.Header-Fonts {
  /*Hero */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 120px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #000000;
  background: rgba(201, 226, 101, 0.2);
}

.Icons-Program-Benefits {
  display: flex;
  justify-content: space-between;
  margin: 60px 80px;
}

.How-It-Works {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 80px;
}

.How-It-Works img {
  margin-bottom: 40px; /* Adjust the value as needed */
}

.Message-Explaining-Program {
  /* Header 1 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #008037;

  padding: 0px 50px 0px;
}

.Participating-Farms-Section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 200px; */
  margin: 64px 0px;
  background: rgba(201, 226, 101, 0.2);
  padding: 0px 50px 20px;
}

.Participating-Farms-Header {
  /*Hero */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  letter-spacing: 0.06em;
  max-width: 560px;

  color: #000000;
}

.Participating-Farms-Text {
  text-align: center;
  margin-top: 0px;
  max-width: 530px;
}

.Farms-Text-Button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Farm-Logo-Cluster {
  position: relative;
  padding: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 500px;
}

.Farm-Image1 {
  width: 150px;
  /* height: 95px; */
  position: absolute;
  top: 100px;
}
.Farm-Image2 {
  width: 200px;
  height: 150px;
  position: absolute;
  transform: rotate(10deg);
  left: 260px;
  top: 125px;
}
.Farm-Image3 {
  width: 200px;
  position: absolute;
  transform: rotate(-10deg);
  left: 250px;
  top: 260px;
}
.Farm-Image4 {
  width: 200px;
  position: absolute;
  transform: rotate(-15deg);
  left: 50px;
  top: 100px;
}
.Farm-Image5 {
  width: 200px;
  /* height: 200px; */
  position: absolute;
}
.Farm-Image6 {
  width: 150px;
  position: absolute;
  transform: rotate(30deg);
  top: 270px;
  left: 120px;
}

.Supporting-Organisations-Div {
  background: rgba(201, 226, 101, 0.2);
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px;
}

.Supporting-Organisations-Header {
  /*Hero */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}

.Supporting-Organisations-Logos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.Footer-Links {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Footer-Font {
  /* line-height: 57px; */
  letter-spacing: 0.06em;
  color: #ffffff;
  text-decoration: none;
}

.HomePage-Line-Divider {
  border-top: 4px solid #9fbf59;
}

.What-Next-Container {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  margin: 0px 20px 100px;
}

.Community-Image-Container {
  margin: 0 10px;
  background-color: #b8cf85;
  border-radius: 40px; /* Adjust the value to control the roundness of the corners */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.What-Next-Text {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.Image-Header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #008037;
  margin-top: 25px;
}

.Subtext {
  background-color: #b8cf85;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  margin-top: 0px;

  color: #000000;
}

.Community-Image-Container img {
  width: 100%;
  height: auto;
}

#communityBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  background-image: url("https://lh3.googleusercontent.com/pw/AP1GczPgvslqfhyM0k_IxdANat9oLFsM7FZLC8jkwKxVEtHvvW43tqvU95CCpb6vPPn0-uULUhu0h89nsKQx8sVvCOTDq9PBbSQa9BO7M-jgLOcS0NXGjUFzIOArrCDMun8aeb--_F4F-sBuiiSF8y5BCp2n=w1396-h1598-s-no-gm?authuser=1"); /* Add your background image URL here */
  background-size: contain;
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
}

.Community-Board-Header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 120px;
  text-align: center;
  letter-spacing: 0.06em;
  margin: 100px;

  color: #000000;
}

.Supporting-Organisations-Text {
  /* Header 1 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.04em;
  margin: 0px;

  color: #008037;
}

@media only screen and (max-width: 980px) {
  .Icons-Program-Benefits {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    width: 50%;
    margin-bottom: 50px; /* Add some space between images on small screens */
    margin-right: 0; /* Remove the right margin on small screens */
  }
}
