.Farmer-Page-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.06em;
  color: #000000;
  padding: 10px 75px 0px 75px;
  background: linear-gradient(rgb(159, 191, 89), rgb(244, 249, 224));
  margin: 0px;
}

.Farmers-Page {
  display: flex;
  background-color: transparent;
  margin: 0px 75px;
  flex-flow: column;
  min-width: 735px;
  padding-bottom: 50px;
}

.Farmers-Line-Divider {
  border-top: 4px solid #9fbf59;
}

.Farmer-Name-Text {
  font-family: "Poppins";
  font-style: normal;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.06em;

  color: #000000;
}

.Farmer-Info-Text {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.08em;

  color: #000000;
}

.Farmer-Description-Text {
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.08em;

  color: #000000;
}

.Farmer-Join-Text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.03em;

  color: #000000;
}
