.Delete-From-Menu {
  background-color: red;
  border-radius: 100%;
  color: white;
  height: 21px;
  width: 21px;
  -webkit-text-stroke: thick;
  border: none;
  margin-left: 10px;
}

.User-Input-Font {
  font-size: 11px;
}

.Add-To-Menu {
  background-color: #3fe03f;
  border-radius: 100%;
  color: white;
  height: 21px;
  width: 21px;
  -webkit-text-stroke: thick;
  border: none;
  margin-left: 10px;
}

.Button-Row {
  display: flex;
  align-items: baseline;
  border-bottom: solid lightgrey 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* justify-content: space-between; */
}

.Save-Cancel {
  display: flex;
  flex-direction: row-reverse;
}
