.FAQ-Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f9e0;
  padding-top: 100px;
}

.FAQ-Questions-Text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
}

.Policy-Line-Divider {
  border-top: 4px solid #9fbf59;
}
