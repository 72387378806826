.Pillar-List {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 0px;
  min-width: 515px;
  max-width: 900px;
}

.How-It-Works-Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f9e0;
  padding-top: 100px;
}

.How-We-Work-Write-Up {
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-bottom: 30px;
}

.How-It-Works-Line-Divider {
  border-top: 2px solid #9fbf59;
}

.HIW-Pillars {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  scroll-margin-top: 110px;
  width: 80%;
}

.Pillar-Buttons {
  border: 2px solid transparent;
  background: #cae366;
  color: #008037;
  border-radius: 100px;
  width: 100%;
  z-index: 1;
}

.Pillar-Buttons:hover {
  text-decoration: underline;
}

/* .Step-Buttons:active{
  border: 2px solid green;
  background: green;
  color: #CAE366;
} */

.Pillar-Buttons-Title-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: inherit;
}

.Pillar-Buttons-Click-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  width: 90px;
  margin: 0px;

  color: #ffffff;
}

.Pillar-Read-More {
  background: #cae366;
  white-space: pre-wrap;
  padding: 75px 27px 50px;
  position: relative;
  bottom: 78px;
  margin-bottom: -78px;
  border-radius: 0px 0px 72px 72px;
}
