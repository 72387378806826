.About-Us-Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f9e0;
  padding-top: 100px;
}

.Concept-Write-Up-And-Image {
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-bottom: 80px;
}

.Our-Concept-Text {
  margin-right: 50px;
}

.How-It-Works-Line-Divider {
  border-top: 2px solid #9fbf59;
}

.HDIW-Steps {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  scroll-margin-top: 110px;
}

.Step-Buttons {
  border: 2px solid transparent;
  background: #cae366;
  color: #008037;
  border-radius: 100px;
  height: 143px;
  width: 450px;
  z-index: 1;
}

.Step-Buttons:hover {
  text-decoration: underline;
}

/* .Step-Buttons:active{
  border: 2px solid green;
  background: green;
  color: #CAE366;
} */

.Step-Buttons-Title-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  width: 250px;

  color: inherit;
}

.Step-Buttons-Step-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  margin: 0px;

  color: #ffffff;
}
.Step-Buttons-Click-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  width: 90px;
  margin: 0px;

  color: #ffffff;
}

.Steps-Read-More {
  background: #cae366;
  white-space: pre-wrap;
  padding: 75px 27px 50px;
  position: relative;
  bottom: 78px;
  width: 450px;
  margin-bottom: -78px;
  border-radius: 0px 0px 72px 72px;
}

.Organisation-Text {
  white-space: pre-wrap;
}

.Supporting-Organisations {
  textalign: "center";
  scroll-margin-top: 55px;
}
