.Marketplace-Page-Header {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
  padding: 0px 50px 50px;
  background: linear-gradient(rgb(159, 191, 89), rgb(244, 249, 224));
}
.Food-Items-Container {
  height: calc(100vh - 400px);
  max-height: 400px;
  overflow-y: scroll;
}

.Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Restaurant-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Select-Pickup-Location {
  color: #3f51b5;
  text-align: center;
  line-height: 40vh;
  font-size: 40px;
}

.Change-Pickup-Location-Row {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
}

.Food-Table {
  display: flex;
  flex-wrap: wrap;
}

.Restaurant-Food-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  position: relative;
}

.Food-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  position: relative;
}

.Food-Cell-Price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  position: relative;
}

.Limited-Remaining {
  font-size: 10px;
  color: red;
  margin: 0;
}

.Delete-Button {
  top: 0;
  right: 0;
  margin: 5px 2px;
  font-size: 10px;
  position: absolute;
  background-color: pink;
  color: darkred;
  border: solid 1px #c51f5a;
  border-radius: 4px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.Delete-Button:hover {
  background-color: red;
  color: darkred;
  border: solid 1px #c51f5a;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.Add-To-Basket {
  color: white;
  /* width:  200px; */
  font-size: 14px;
  background-color: #9fbf59;
  border-radius: 4px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.Add-To-Basket:hover {
  background-color: #1c8c1c;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.Sign-In-Sign-Out-Buttons {
  display: flex;
}

.Sign-In-Button-Marketplace {
  max-width: 100%;
  font-size: 1rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  color: white;
  margin: 4px;
  padding: 9px;
  display: inline-flex;
  min-height: 40px;
  width: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-radius: 1000px;
  border: none;
  cursor: pointer;
  transition:
    background-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  user-select: none;
  text-decoration: none;
  text-align: center;
  background-color: #e177f5;
}

.Sign-Up-Button-Marketplace {
  max-width: 100%;
  font-size: 1rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  color: white;
  margin: 4px;
  padding: 9px;
  display: inline-flex;
  min-height: 40px;
  width: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-radius: 1000px;
  border: none;
  cursor: pointer;
  transition:
    background-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  user-select: none;
  text-decoration: none;
  text-align: center;
  background-color: #0f940f;
}

.Checkout-Add-Items-Buttons {
  display: flex;
  flex-direction: column;
  background: transparent;
  position: sticky;
  top: 70px;
}

.Category-Selection-Group {
  display: flex;
  align-items: center;
}

.EmptyMarketplace {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 700px;
  padding: 10px;
}

/* Creating the rows of menu items with two items on each row */
.Food-List {
  display: grid;
  grid-template-columns: repeat(auto-fill, 33%);
}

.Food-Element {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 0px;
  position: relative;
  white-space: nowrap;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin: 5px;
  background: #f6f6f6;
  border-radius: 5px;
}

.Food-Element:hover {
  white-space: normal;
}

.Farm-Name-Marketplace {
  margin-top: 5px;
  margin-bottom: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
  /* identical to box height, or 136% */
  align-items: center;
  letter-spacing: 0.1px;

  color: #a5a5a5;

  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.Item-Name-Marketplace {
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.08em;
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
  /* identical to box height, or 119% */
  /* display: flex; */
  align-items: center;

  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Item-Price-Marketplace {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  margin-right: 10px;

  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;

  color: #000000;
}

.Item-Description-Marketplace {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* or 100% */
  text-align: right;
  letter-spacing: 0.08em;

  margin: 0px 0px 0px 10px;
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
}

.Minimum-Purchase {
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  color: rgb(16 0 255);
  margin: 0px 0px 0px 0px;
}

.Item-Package-Fee {
  font-family: "Poppins";
  font-style: normal;
  font-size: 8px;
  color: red;
  margin: 0px 0px 0px 0px;
}

.Marketplace-Info {
  /* position: absolute;
  top: 20px;
  left: 0; */
  font-size: 12px;
  font-weight: 800;
}

.Marketplace-Title {
  font-size: 36px !important;
  font-weight: 900;
  color: Black;
  text-align: center;
  max-width: 400px;
}

.Food-Card {
  margin: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.Food-Card:hover {
  white-space: normal;
}

.Item-Details {
  margin-left: 10px;
  width: 180px;
}

.Minus-Button {
  background-color: #9fbf59;
  height: 42.75px;
  border-radius: 10px 0px 0px 10px;
}

.Incremental-Counter {
  display: flex;
  align-items: center;
  margin: 10px;
}

.Minus-Button:hover {
  background-color: #80984a;
}

.Plus-Button {
  background-color: #9fbf59;
  height: 42.75px;
  border-radius: 0px 10px 10px 0px;
}
.Plus-Button:hover {
  background-color: #80984a;
}

.Increment-Number {
  padding: 0px 15px 0px 15px;
  font-size: 20px;
  border-top: 1px solid #9fbf59;
  border-bottom: 1px solid #9fbf59;
  height: 42.75px;
  display: flex;
  align-items: center;
  margin: 0px;
}

.Marketplace-Incremental-Counter {
  display: flex;
  align-items: center;
  margin: 0px 10px 10px;
}

.Image-Limited-Box {
  position: relative;
}

.Marketplace-Limited-Remaining {
  font-size: 12px;
  color: white;
  margin: 2px;
  position: absolute;
  background: #000000ba;
  border-radius: 5px;
  padding: 5px;
  border: #2a0052 1px solid;
  top: 5px;
}

.Title-Marketplace {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.06em;
  color: #000000;
  margin: 0px 25px;
}

.Pickup-Location-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 57px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-align: end;
  /* max-width: fit-content; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  color: #000000;
}

.Distribution-Location-Details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  align-items: center;
  /* text-align: right; */
  letter-spacing: 0.08em;
  width: 400px;

  color: #000000;
}

.Marketplace-Grid-Header {
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: baseline;
}

.Location-Title-And-Details {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.Community-Title-And-Distribution-Location {
  align-items: end;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1133px) {
  .Location-Title-And-Details {
    align-items: start;
  }
  .Community-Title-And-Distribution-Location {
    align-items: start;
  }
}

.Quantities-In-Basket {
  margin: 0px 0px 10px 0px;
  font-size: 12px;
  color: black;
  background: rgb(191 223 116);
  border-radius: 5px;
  padding: 2px 5px;
}

html {
  scroll-behavior: smooth;
}

.Sold-Out-Sign {
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-top: 5px;
  z-index: 1;
  background: rgb(229 242 176 / 63%);
}

.Food-Image {
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-top: 5px;
}

.Select-Distribution-Link-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Select-Distribution-Link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  /* height: 180px; */
  margin: 50px 0px 100px;
}
