.leaflet-container {
  width: 70%;
  height: 80vh;
  z-index: 1;
}

.List-Of-Locations-Delivering {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 100px;
  margin-right: 10px;
  border: solid 2px darkblue;
  height: 80vh;
  overflow-y: scroll;
}

.List-Of-Locations {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 100px;
  margin-right: 10px;
  /* border: solid 2px darkblue; */
  height: 80vh;
  overflow-y: auto;
  /* max-width : 30%; */
}

.List-And-Map {
  display: flex;
}

.Location-Element {
  display: grid;
  min-width: 100%;
  align-items: center;
  border-bottom: solid 2px darkblue;
  padding: 0px 7px 0px;
}

.Filter-By-Ingredient-Form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Filters-By-Location {
  display: flex;
  flex-direction: row;
}
