.Row-Title {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.Tab-Box {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin: 10px;
  border: solid 1px;
}

.Tab-Item-Row {
  display: flex;
  align-items: baseline;
}

/* My orders Tab */
.Food-Item:nth-child(even) {
  background-color: #b9a0ff;
  font: 1em;
  margin: 0px;
  flex-grow: 1;
}

.Food-Item:nth-child(odd) {
  background-color: #b4b9ec;
  font: 1em;
  margin: 5px 0px;
  flex-grow: 1;
}

.Pantry-Food-Item {
  background-color: rgb(114 255 113);
  font: 1em;
  margin: 5px 0px;
  flex-grow: 1;
}

.Food-Item-Desc-Quantity {
  display: flex;
  justify-content: space-between;
}

.Food-Item-Tags-And-Name {
  display: flex;
  align-items: center;
}

.Food-Item-Child {
  padding: 0px 5px 0px;
  font-size: 15px;
  margin: 2px 0px;
}

.Recurring-Stamp {
  color: red;
  font-size: 10px;
  margin: 5px;
}

.Order-Bottom-Row {
  display: flex;
  justify-content: space-between;
}

.My-Menu-Button {
  display: flex;
  margin: 10px;
}

.Order-Date {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.Order-Date-Distribution-Location {
  display: block;
  font-size: 1.17em;
  margin-bottom: 0px;
  font-weight: bold;
}

.Pickup-Location-Order-Details {
  font-size: 11px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.Pickup-Location-Account-Header {
  font-size: 13px;
  text-decoration: underline;
}

.Food-Item-Header {
  display: block;
  font-weight: bold;
}

.Order-Again-Button {
  margin: 10px;
}

.Contact-Method-Title {
  padding: 10px 5px 0px;
  font-size: 15px;
  margin: 2px 0px;
}

.Contact-Method-Item {
  padding: 0px 5px 0px;
  font-size: 15px;
  margin: 2px 0px;
}

.Prices-Per-Farm {
  font-size: 15px;
  margin: 2px 0px;
}

.Subsidy-Donation-Totals {
  font-size: 15px;
  margin: 2px 0px 2px 5px;
}

.User-Total-Payment {
  display: flex;
  align-items: center;
}

.Price-Totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  margin: 10px;
}

.My-Account-Item {
  margin: 0px 10px 0px;
  font-size: 0.83em;
}

.Recurring-Orders {
  margin: 10px;
}

.Order-Food-List {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: white;
  margin: 0px 5px 0px;
  min-width: 300px;
}

.Order-Food-List-Style {
  font: 1em;
  margin: 0px;
}

.Order-History-Button {
  margin: 5px;
}

.Donation-Message {
  margin: 10px;
  width: 300px;
}

.Farm-Add-Row {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  justify-content: space-between;
  min-width: 500px;
}

.Farm-Info-Button {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
}

.Farm-Info {
  display: flex;
  border: solid 1px;
  padding: 10px;
  flex-grow: 1;
  justify-content: space-between;
}

.Farm-Info-List {
  display: flex;
  border: solid 1px;
  padding: 10px;
  flex-grow: 1;
}

.Farm-Info-Column1 {
  display: flex;
  flex-direction: column;
  max-width: 225px;
}

.Farm-Info-Column1-List {
  display: flex;
  flex-direction: column;
  max-width: 225px;
  margin-right: 100px;
}

.Farm-Info-Column2 {
  display: flex;
  flex-direction: column;
  max-width: 225px;
}

.Sourcing-Farms-Chips {
  display: flex;
  flex-direction: column;
}

.Sourcing-Farm-Info-Button {
  display: flex;
}

.Community-Members-Boxes {
  display: flex;
  border: solid 1px;
  padding: 10px;
  margin: 2px;
}

.Approve-Delete-Buttons {
  display: flex;
  flex-direction: column;
}

.List-Community-Locations {
  display: flex;
  flex-direction: column;
}

.Edit-Buttons-Distribution-Location {
  display: flex;
  gap: 12px;
}

.Marketplace-Banner-Message {
  border-radius: 5px;
  background: rgb(255, 49, 38);
  font-weight: 400;
  color: rgb(1, 67, 97);
  padding: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  margin: 18px;
  text-align: center;
}

.Community-Host-Banner-Message {
  border-radius: 5px;
  background: rgb(229, 246, 253);
  font-weight: 400;
  color: rgb(1, 67, 97);
  padding: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  margin: 18px;
  text-align: center;
}

.Marketplace-Banner-Title {
  font-weight: 600;
  color: rgb(1, 67, 97);
}

.Edit-Community-Items-Distribution-Location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px;
}

.Payment-Number-Removal {
  display: flex;
  align-items: center;
}

.Bar-Graph {
  display: flex;
  overflow: hidden;
  border-radius: 15px;
  height: 13px;
}

.Multicolor-Bar .values {
  display: flex;
}

.Multicolor-Bar .values .value {
  /* float: left; */
  text-align: center;
  overflow: hidden;
  max-height: 20px;
}

.Multicolor-Bar .values .value:hover {
  overflow: visible;
  max-height: none;
}

.Multicolor-Bar .scale {
  display: flex;
}

.Multicolor-Bar .scale .graduation {
  /* float: left; */
  text-align: center;
  overflow: hidden;
}

.Multicolor-Bar .scale .graduation:hover {
  overflow: visible;
}

.Multicolor-Bar .legends {
  text-align: center;
}

.Multicolor-Bar .legends .legend {
  display: inline-block;
  margin: 0 0px;
  text-align: center;
  font-size: 10px;
}

.Multicolor-Bar .legends .legend .dot {
  vertical-align: middle;
}

.Multicolor-Bar .legends .legend .label {
  margin-right: 5px;
  vertical-align: middle;
}

.Customers-Per-Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  /* background : #3f51b5; */
  /* color: white; */
}

.Distribution-Locations-Per-Item {
  margin: 5px 0px;
}

.Order-Sort-Options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.My-Account-Fees {
  font-family: "Poppins";
  font-style: normal;
  /* identical to box height */
  letter-spacing: 0.06em;
  margin: 2px 0px;
  color: red;
}
