.Title-Row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.Hidden-Element {
  visibility: hidden;
}

.Header-2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
