.Menu-Header-Background-Image {
  background-size: 100%;
  border-bottom: solid darkblue;
  margin-bottom: 14px;
}

.Menu-Page-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* The height of the Menu Box */
  height: 100px;
}

.Restaurant-Title {
  font-size: 32px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.Restaurant-Info {
  font-size: 16px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.centered {
  display: inline-block;
  position: relative;
  padding: 10px;
  background-color: white;
  border-radius: 25px;
}
.left {
  position: absolute;
  right: 100%;
  padding: 0px 10px 0px;
  top: 1px;
}

.right {
  position: absolute;
  left: 100%;
  width: 100px;
  padding: 10px;
  top: 0px;
}

.Restaurant-Logo {
  object-fit: cover;
  height: 100px;
}

.Selection-Options {
  padding-left: 10px;
}

.Menu-Selection-Title {
  font-size: 14px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

/* The Header of the Menu Page */
.Menu-Selection-Row {
  display: flex;
}

/* Creating the rows of menu items with two items on each row */
.Menu-List {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  min-width: 400px;
}

.Section {
  font-size: 24px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  margin-bottom: 14px;
  margin-top: 0px;
}

/* Describing each individual menu box item */
.Menu-Item {
  display: flex;
  flex-direction: column;
  min-width: 185px;
  margin-bottom: 14px;
}

.Item-Name {
  font-size: 16px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.Local-Ingredients {
  font-size: 14px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  white-space: normal;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.No-Local-Ingredients {
  font-size: 14px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  white-space: normal;
  margin: 0px;
  padding: 0px;
}

.Menu-Item-Box {
  margin-right: 50px;
  flex-grow: 1;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Menu-Item-Text {
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Food-Item-Photo {
  display: none;
}

.Food-Item-Photo[src] {
  display: flex;
  height: 130px;
  width: 30%;
  object-fit: cover;
}

.Menu-Item-Row {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
}

.Menu-Item-Details-Button {
  color: blue;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 3px 0px 5px;
}

/* This is all for the dialog that opens when you click more details */

.Close-Button {
  top: 0;
  right: 0;
  position: absolute;
  background-color: pink;
  color: darkred;
  border: solid 1px #c51f5a;
  border-radius: 4px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.Close-Button:hover {
  background-color: red;
  color: darkred;
  border: solid 1px #c51f5a;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.Date-Of-Meal {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: solid 1px;
  margin: 0px;
  font-size: 16px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  max-height: 40px;
  white-space: normal;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.Local-Ingredients-Header {
  font-size: 16px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 5px;
  padding: 0px;
  overflow: hidden;
}

.No-Farm-Data:nth-child(even) {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
  border-radius: 4px;
  padding: 16px;
}

.No-Farm-Data:nth-child(odd) {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 239, 237);
  border-radius: 4px;
  background-color: rgb(229, 239, 237);
  padding: 16px;
}

.Ingredient-List:nth-child(even) {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
  border-radius: 4px;
  padding: 16px;
}

.Ingredient-List:nth-child(even):hover {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 4px;
  padding: 16px;
}

.Ingredient-List:nth-child(odd) {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 239, 237);
  border-radius: 4px;
  background-color: rgb(229, 239, 237);
  padding: 16px;
}

.Ingredient-List:nth-child(odd):hover {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 4px;
  padding: 16px;
}

.List-Container {
  padding: 0px !important;
  border: solid 2px;
  min-width: 400px;
}

.Dialog-Item-Text {
  margin: 0px;
  font-size: 15px;
  font-family:
    DD-TTNorms,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  max-height: 40px;
  white-space: normal;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

/* Describing each ingredient in the pop up Menu */
.Important-Dates-Column {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.First-Row {
  display: flex;
  justify-content: space-between;
}
