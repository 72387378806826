.Like-Num {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Up-Down-Vote {
  display: flex;
  flex-direction: column;
}

.Personal-Vote-Super-Script {
  top: 0;
  margin-top: 10px;
}
