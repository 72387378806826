.Title-And-Select-Button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.Distribution-Location-Header {
  padding: 0px 50px 5px;
  background: #f4f9e0;
  margin: 50px 0px 0px;
}

.Distribution-Location-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.06em;
  color: #000000;
}

.Distribution-Location-Sub-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  color: #000000;
  letter-spacing: 0.06em;

  color: #000000;
}

.Distribution-Location-Page {
  margin: 20px 50px;
}

.Pickup-Location-Name-Header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  margin-right: 5px;

  color: #000000;
}

.Pickup-Location-Details-Selection {
  margin: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.08em;

  color: #000000;
}

.Selection-Location-Element {
  display: grid;
  min-width: 100%;
  align-items: center;
  padding: 0px 7px 20px;
  background: rgb(202, 221, 158);
  border-radius: 10px;
}

.Selection-Location-Element:not(:first-child) {
  margin-top: 10px;
}

.Selection-Location-Element:hover {
  background: rgb(176 190 141);
}

.Selection-Community-Location-Element {
  display: grid;
  min-width: 100%;
  align-items: center;
  padding: 0px 7px 20px;
  background: rgb(167 241 107);
  border-top: 10px solid white;
}

.Selection-Community-Location-Element:hover {
  background: rgb(146 211 93);
}

.Distribution-Location-Legend {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  flex-basis: 0;
}

@media only screen and (max-width: 850px) {
  .Distribution-Location-Legend {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.Legend-Accordion {
  color: #cadd9e;
}

.Distribution-Selection-Header-Buttons {
  display: flex;
  margin-bottom: 10px;
}

.List-And-Map-Distribution-Selection {
  display: flex;
  margin-top: 20px;
}

.Legend-Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0.06em;

  color: #000000;
}
