.Sign-In-Page {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.User-Inputs {
  display: flex;
  flex-direction: column;
  height: 44px;
  justify-content: space-between;
}

.Form {
  display: flex;
  flex-direction: column;
}
.Sign-In-Title {
  font-size: 1.5rem !important;
  line-height: 1.334 !important;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.015625em !important;
  font-weight: 700 !important;
}
