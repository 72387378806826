/* Header stuff */
.Header-Margin {
  margin-bottom: 2px;
  margin-top: 3px;
}

.Header-2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.Header-3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.Header-4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-size: 15px;
}

.Header-5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* End of Header Stuff */

.Center-Element {
  display: flex;
  justify-content: center;
}

.Flex-Row-Stretch {
  display: flex;
  align-items: stretch;
}

.Flex-Row-End {
  display: flex;
  justify-content: flex-end;
}

.Flex-Row-Align-Items-End {
  display: flex;
  align-items: center;
}

.Flex-Column {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.Next-Button {
  margin: 12px;
}

.Plus-Min-Button {
  background-color: white;
  border-left: solid 1px;
  border-right: solid 1px;
  min-height: 100%;
}

.Plus-Button {
  background: #00800078;
}
.Minus-Button {
  background: #ff000061;
}

.Load-More-Button {
  display: flex;
  flex-direction: row-reverse;
  margin: 0px 10px 10px;
}

.Quantity-Num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: white;
  height: 56px;
}

.Edit-Button {
  border: none !important;
  margin: 0px 0px 5px !important;
  padding: 0px !important;
  min-width: 20px !important;
}

/* Page Stuff */
.Page-Market {
  display: flex;
  background-color: transparent;
  margin: 0px 50px 50px;
  flex-flow: column;
  min-width: 735px;
  scroll-margin-top: 55px;
}
.Page-Title {
  font-size: 30px;
  color: red;
  background-color: white;
}

.Page-Header {
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: baseline;
  position: relative;
}

/* Menu that is displayed on each page */
.Menu {
  display: flex;
  z-index: 1000;
  height: 50px;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 1rem;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background: url("https://lh3.googleusercontent.com/pw/AM-JKLXIW2NwZoUbo1l5NFqt-mQl-dLL-SjPQZyeVtmrFpWCVsNlsiMgLlpF59jJff-83lt1EfRGFysxFZVNDIm9uY1xbD7DuZV4y8bFGyJKMrKtMkJ1GIv4LEV1uDvjDXNJR9HxL798Zp86bBkK0lTKhsmz=w1440-h110-no?authuser=1"),
    #d09c74;
  background-repeat: no-repeat;
  background-size: cover;
  referrer-policy: "no-referrer";
}

.Menu-Hidden {
  display: flex;
  visibility: hidden;
  background-color: white;
  justify-content: center;
  color: black;
  border: solid;
  width: 110px;
}

.Header-Links {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  background-color: transparent;
  flex-grow: 1;
}

.Menu-Logo-Style {
  border: none;
  height: 35px;
}

.Menu-Basket-Style {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  margin-bottom: -6px;
  margin-right: 1.3rem;
  position: relative;
  top: 3px;
  font-size: 1.2rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  text-decoration: none;
}

.Menu-Basket-Style:hover {
  border-color: white;
}

.Menu-Basket-Style-Selected {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: -6px;
  margin-right: 1.3rem;
  position: relative;
  top: 3px;
  font-size: 1.2rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  text-decoration: none;
}

.Menu-Font-Style {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;
  margin-bottom: -6px;
  margin-right: 1.3rem;
  font-size: 1.2rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  text-decoration: none;
}

.Menu-Font-Style:hover {
  border-color: white;
}

.Menu-Font-Style-Selected {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  padding-bottom: 4px;
  margin-bottom: -6px;
  margin-right: 1.3rem;
  font-size: 1.2rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  text-decoration: none;
}

.Menu-Item-Font-Style {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 1.2rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.015625em;
  font-weight: 700;
  text-decoration: none;
}

.Sign-Up-Button {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  font-family: "DD-TTNorms", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-bottom: 4px;
  margin-bottom: -6px;
  margin-right: 1.3rem;
  letter-spacing: 0.015625em;
  font-weight: 700;
  color: white;
  text-decoration: none;
  margin: 0px;
  padding: 9px;
  display: inline-flex;
  min-height: 40px;
  width: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-radius: 1000px;
  border: none;
  cursor: pointer;
  transition:
    background-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  user-select: none;
  text-decoration: none;
  text-align: center;
  background-color: #0f940f;
}

.Menu-Row {
  display: flex;
  align-items: center;
}

/* Tab stuff */
.tab-list {
  border-bottom: 1px solid #ccc;
  list-style: none;
  list-style-type: none;
  display: flex;
  padding-left: 12px;
}

.tab-list-item {
  display: flex;
  list-style-type: none;
  padding: 12px 12px 6px 12px;
  margin-bottom: -1px;
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  display: flex;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  padding: 12px 12px 0 12px;
}

.tab-list-hidden {
  display: none;
}

/* Community Board Page */

/* Company Tab */
.Top-10-Issue-List {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 150px);
  padding-top: 1em;
  padding-left: 1em;
  padding-bottom: 1.33em;
  border-radius: 1%;
  border: solid 1px;
  min-width: 130px;
}

.Top-10-Issues-Box {
  display: flex;
  flex-direction: column;
  margin: 0px 100px 50px;
}

.Like-Button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.Naked-Button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.Company-Position {
  font-style: italic;
}

.Employee-Desc-Row {
  display: flex;
}

/* Issue List Page  */
.Issue-Page {
  display: flex;
  justify-content: space-between;
}

.Issue-Element {
  display: grid;
  grid-template-columns: 10% auto 34px 100px;
  align-items: center;
  border-bottom: solid 2px darkblue;
  padding: 0px 7px 0px;
}

.Issue-Title {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.Issue-List-Element {
  border: solid 2px darkblue;
  /* The 150px is the amount of the Issue List Title and a bit more to subtracted
  from the overall screen size */
  height: calc(100vh - 150px);
  /* The 630px is because each element is at least 70px in height and to ensure
  that lazy loading works the list box can never be greater than all 10 Items
  in the list so 70*9=630px */
  max-height: 630px;
  overflow-y: scroll;
}

.Issue-List {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 45%;
  min-width: 300px;
}

.Issue-Details-Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  min-width: 300px;
  margin-top: 6em;
  padding: 0 1em 0;
  border: solid;
  /* The 150px is the amount of the Issue List Title and a bit more to subtracted
  from the overall screen size */
  height: calc(100vh - 160px);
  /* The 630px is because each element is at least 70px in height and to ensure
  that lazy loading works the list box can never be greater than all 10 Items
  in the list so 70*9=630px */
  max-height: 630px;
  overflow-y: scroll;
}

.Order-Details-Text {
  margin: 10px 0px 10px 0px;
}

.Placing-Order-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 85px;
}

.Select-Money-Totals {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Customer-Payment-Edit-Row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.Food-Item-Amount-Row {
  display: flex;
  flex-direction: row;
}
.Food-Item-Amount-Labels {
  display: flex;
  flex-direction: column;
}
.Pre-Ordered-Label {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
.Pre-Ordered-Amount {
  background-color: #168fff;
  max-width: 70px;
  width: auto;
  margin: 1em 0px 1em 10px;
  padding: 0px 5px 0px 5px;
  height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}

.Pre-Ordered-Amount:hover {
  overflow: visible;
  max-width: 310px;
  width: auto;
  white-space: normal;
  height: auto;
}

.Distributed-Label {
  display: flex;
  flex-direction: row;
}

.Distributed-Amount {
  background-color: #f75b86;
  max-width: 70px;
  width: auto;
  margin: 1em 0px 1em 10px;
  padding: 0px 5px 0px 5px;
  height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}

.Distributed-Amount:hover {
  overflow: visible;
  max-width: 310px;
  width: auto;
  white-space: normal;
  height: auto;
}

.User-Name-Remove-Button {
  display: flex;
}

.Change-Item-Price {
  display: flex;
  align-items: baseline;
}

.Editing-Price-Change {
  display: flex;
  align-items: baseline;
}

.Editing-Remove-Food-Item {
  display: flex;
}

.Modify-Orders-Check {
  display: flex;
}

.Accordian-Font-Text {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.Name-Guaranteed-Pickup {
  display: flex;
  align-items: baseline;
}

.Taken-From-Pantry-Stats {
  font-size: 10px;
  color: red;
  margin: 0;
}

.Loading-Text {
  width: 200px;
  text-align: center;
  color: #4caf50;
}

.Full-Screen-Loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgb(244, 249, 224);
}

.Inline-Screen-Loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
