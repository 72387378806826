.Confirmation-Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px 20px 40px;
}

.Confirmation-Page-Items {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Thank-You {
  font-family: Buenard;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;

  color: #000000;
}

.Confirmation-Email {
  font-family: Buenard;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 52px;
  text-align: center;

  color: #000000;
}

.Next-Steps-Text {
  font-family: Buenard;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}

.Next-Steps-Title {
  font-family: Buenard;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 38px;
  text-decoration-line: underline;

  color: #000000;
}

.Social-Media {
  display: flex;
  align-items: center;
  margin: 50px 0px 0px;

  font-family: Buenard;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
}

.Community-Board-Plug {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #000000;
}
