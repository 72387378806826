.Pantry-Cell {
  display: flex;
  margin: 5px 0px 5px 0px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  background: #efcc62;
  position: relative;
}

.Pantry-Delete-Button {
  top: 0;
  right: 0;
  margin: 0px 2px;
  font-size: 10px;
  position: absolute;
  background-color: pink;
  color: darkred;
  border: solid 1px #c51f5a;
  border-radius: 4px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.Pantry-Delete-Button:hover {
  background-color: red;
  color: darkred;
  border: solid 1px #c51f5a;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
