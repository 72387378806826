/* Basket Page */

.Customer-Info-Distribution-Location-Header {
  display: flex;
  align-items: baseline;
  width: 400px;
  justify-content: space-between;
  margin-bottom: -20px;
}

.Select-Distribution-Date-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 510px;
}

.Basket-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.66%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Restaurant-Basket-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Basket-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.66%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  text-align: center;
}

.Basket-Pantry-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.66%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(114 255 113);
  text-align: center;
}

.Basket-Cell-Price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 16.66%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  position: relative;
}

.Restaurant-Basket-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  text-align: center;
}

.Weekly-Checkbox {
  display: flex;
  flex-direction: row;
  margin: 10px;
  max-width: 75px;
  align-items: center;
}

.Farm-Headers-Farm-Rows {
  width: 100%;
}

.Individual-Farm-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
}

.Distribution-Farm-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 16.6%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
}

.Restaurant-Farm-Table-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 16.6%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: white;
}

.Individual-Farm-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  overflow: hidden;
  list-style: none;
  padding: 8px 0px 8px;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  font-size: 16px;
  text-align: center;
}

.Distribution-Farm-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.6%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  font-size: 16px;
  text-align: center;
}

.Restaurant-Farm-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.6%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  font-size: 16px;
  text-align: center;
}

.Farm-Select-Cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.6%;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
  font-size: 16px;
  text-align: center;
  padding: 8px 0px 8px;
}

.Add-Items-Header {
  text-decoration: underline;
  margin: 0px;
  padding: 10px 0px;
  border-bottom: solid 1px;
}

.Farm-Contact-Info {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  font-size: 12px;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
}

.Distribution-Farm-Contact-Info {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 16.6%;
  font-size: 12px;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
}

.Restaurant-Farm-Contact-Info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 16.6%;
  font-size: 12px;
  overflow: hidden;
  list-style: none;
  border-bottom: solid 1px;
  background: rgb(226, 247, 243);
}

.Farm-Pickup-Location-Info {
  display: flex;
  justify-content: space-between;
}

.Pickup-Location-Box {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  min-width: 250px;
}
.Pickup-Location-Header {
  border-bottom: 1px solid black;
  padding: 0px 10px 0px;
  background: #76ff84;
}

.Location-Title-Change-Button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.Pickup-Location-Details {
  padding: 0px 10px 0px;
  font-size: 13px;
  background: #e7ffde;
}

.Checkout {
  display: flex;
  margin: 10px;
}

.Distribution-Donation-Subsidy-Totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;
}

.Totals-And-Button {
  display: flex;
  flex-direction: column;
}

.Radio-TextField {
  display: flex;
  align-items: center;
  width: 230px;
}

.Donation-Subsidy-Text-Field {
  display: flex;
  align-items: baseline;
}

.Info-And-Radio-Button {
  display: flex;
  align-items: baseline;
}

.Title-Basket {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 0.06em;
  color: #000000;
  margin: 0px 25px;
}

.Basket-Item-Name-Font {
  /* Header 3 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */
  letter-spacing: 0.06em;

  color: #000000;
}

.Basket-Farm-Name-Font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  /* identical to box height, or 100% */
  letter-spacing: 0.08em;

  color: #000000;
}

.Price-And-Description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.06em;

  color: #000000;
}

.Total-Price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.06em;

  color: #000000;
}

.Basket-Limited-Remaining {
  font-size: 12px;
  color: white;
  margin: 2px 7px;
  position: absolute;
  background: #000000ba;
  border-radius: 5px;
  padding: 5px;
  border: #2a0052 1px solid;
  top: 5px;
}

.Basket-Row {
  display: flex;
  border: solid 1px black;
  border-radius: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  min-width: 560px;
  background: white;
}

.Counter-Price-Description {
  align-items: end;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.Order-Summary-Box {
  border: 2px solid #9fbf59;
  padding: 10px;
  border-radius: 5px;
  background: white;
  height: fit-content;
}

.Order-Summary-And-Food-Items {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Total-Owed-Font {
  /* Header 2 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 57px;
  /* identical to box height */
  letter-spacing: 0.06em;
  margin: 0px;
  text-align: end;

  color: #000000;
}

.Total-Packaging-Fees {
  font-family: "Poppins";
  font-style: normal;
  /* identical to box height */
  letter-spacing: 0.06em;
  margin: 2px 0px;
  color: red;
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.Total-Fees {
  font-family: "Poppins";
  font-style: normal;
  /* identical to box height */
  letter-spacing: 0.06em;
  margin: 2px 0px;
  color: red;
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.Farm-Total-Line-Individual {
  white-space: nowrap;
  margin: 5px 0px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 5px;
  /* border-top: 1px solid black; */
  border-bottom: 1px dotted black;
}

.Volunteer-Fee-Box {
  border: solid 1px black;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 10px 0px;
}

.Max-Subsidy-Shake-Animation {
  animation: shake 0.5s;
  animation-iteration-count: 2;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
